export const STATUS_CODES = {
  INCOMING: 1,
  BULLPEN: 2,
  PRE_INSPECTION: 3,
  GPS: 4,
  TECHNICIAN_INSPECTION: 5,
  TECHNICIAN_BUILD: 6,
  HEAVY_LINE: 7,
  VENDOR_SUBLET: 8,
  QC: 9,
  STATE_INSPECTION: 10,
  DETAIL: 11,
  BRANCH: 12,
  LOT_REPAIR: 13,
  REJECTED: 14,
  DONOR: 15,
  IN_TRANSIT: 16,
};

export const PROGRESS_BAR_STATUSES = Object.values(STATUS_CODES).filter(
  v => v !== STATUS_CODES.BRANCH && name !== STATUS_CODES.REJECTED
);

export const STATUS_MAP = {
  [STATUS_CODES.INCOMING]: { name: 'Incoming', slug: 'incoming' },
  [STATUS_CODES.BULLPEN]: { name: 'Bullpen', slug: 'bullpen' },
  [STATUS_CODES.PRE_INSPECTION]: {
    name: 'Pre-Inspection',
    slug: 'pre_inspection',
    short: 'PreInsp',
  },
  [STATUS_CODES.GPS]: { name: 'GPS', slug: 'gps' },
  [STATUS_CODES.TECHNICIAN_INSPECTION]: {
    name: 'Technician Inspection',
    slug: 'technician_inspection',
    short: 'TechInsp',
  },
  [STATUS_CODES.TECHNICIAN_BUILD]: {
    name: 'Technician Build',
    slug: 'technician_build',
    short: 'Build',
  },
  [STATUS_CODES.VENDOR_SUBLET]: {
    name: 'Vendor/Sublet',
    slug: 'vendor_sublet',
    short: 'Vendor',
  },
  [STATUS_CODES.HEAVY_LINE]: { name: 'Heavy Line', slug: 'heavy_line', short: 'HL' },
  [STATUS_CODES.QC]: { name: 'QC', slug: 'qc' },
  [STATUS_CODES.STATE_INSPECTION]: {
    name: 'State Inspection',
    slug: 'state_inspection',
    short: 'StateInsp',
  },
  [STATUS_CODES.DETAIL]: { name: 'Detail', slug: 'detail' },
  [STATUS_CODES.BRANCH]: { name: 'Branch', slug: 'branch' },
  [STATUS_CODES.LOT_REPAIR]: {
    name: 'Lot Repair',
    slug: 'lot_repair',
    short: 'LotRepair',
  },
  [STATUS_CODES.REJECTED]: { name: 'Rejected', slug: 'rejected' },
  [STATUS_CODES.DONOR]: { name: 'Donor', slug: 'donor' },
  [STATUS_CODES.IN_TRANSIT]: { name: 'In Transit', slug: 'in_transit' },
};

export const COMPLETE_STEP_STATUSES = [
  STATUS_CODES.INCOMING,
  STATUS_CODES.BULLPEN,
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.GPS,
  STATUS_CODES.TECHNICIAN_INSPECTION,
  STATUS_CODES.TECHNICIAN_BUILD,
  STATUS_CODES.VENDOR_SUBLET,
  STATUS_CODES.QC,
  STATUS_CODES.STATE_INSPECTION,
  STATUS_CODES.DETAIL,
  STATUS_CODES.LOT_REPAIR,
];

export const NON_CHECKLIST_STATUSES = [
  STATUS_CODES.INCOMING,
  STATUS_CODES.BULLPEN,
  STATUS_CODES.GPS,
  STATUS_CODES.VENDOR_SUBLET,
  STATUS_CODES.STATE_INSPECTION,
  STATUS_CODES.DETAIL,
  STATUS_CODES.BRANCH,
];

export const CHECKLIST_STATUSES = [
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.TECHNICIAN_INSPECTION,
  STATUS_CODES.TECHNICIAN_BUILD,
  STATUS_CODES.QC,
  STATUS_CODES.LOT_REPAIR,
];

export const STOP_WORK_DIALOG_STATUSES = [
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.TECHNICIAN_INSPECTION,
  STATUS_CODES.TECHNICIAN_BUILD,
  STATUS_CODES.QC,
];
export const INITIAL_INSPECTION_STATUSES = [
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.TECHNICIAN_INSPECTION,
];

export const PARTS_STATUSES = [
  STATUS_CODES.PRE_INSPECTION,
  STATUS_CODES.TECHNICIAN_INSPECTION,
  STATUS_CODES.TECHNICIAN_BUILD,
  STATUS_CODES.LOT_REPAIR,
];

export const SLUG_STATUS_MAP = Object.keys(STATUS_MAP).reduce(
  (r, k) => ({ ...r, [STATUS_MAP[k].slug]: k }),
  {}
);

export const HISTORY_TAB_CODE = '__HISTORY__';
export const PARTS_TAB_CODE = '__PARTS__';

export const USER_GROUPS = {
  SHOP_SUPERVISORS: 1,
};

export const USER_GROUPS_MAP: { [id: number]: string } = {
  [USER_GROUPS.SHOP_SUPERVISORS]: 'Shop Supervisors',
};
